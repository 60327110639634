<template>
    <div class="recordWrap">
        <div class="recordTop">
            <p>当前活动：{{infoList.activityName}}</p>
            <p>起始时间：{{infoList.activityTimeStr}}</p>
            <p>截止时间：{{infoList.activityEndTimeStr}}</p>
            <p>报名人数：{{showNum}}</p>
        </div>
        <div class="record">
            <div class="recordMin" v-for="(item,index) in recordList">
                <div class="minLeft">
                    <p>昵称：{{item.joinUserName}}</p>
                    <p>时间：{{item.joinTimeStr}}</p>
                    <p>TEL：{{item.joinPhone}}</p>
                    <p>留言：{{item.joinMessage}}</p>
                    <div>
                        <el-input
                                style="width:500px;"
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="item.activityMessage">
                        </el-input>
                    </div>
                </div>
                <div class="minRight">
                    <el-image
                            style="width: 100px; height: 100px"
                            src="/officialWebsiteMgr/resource/file/getImage?fileName=202301022020029748LY.jpg"
                            fit="scale-down"></el-image>
                    <span v-if="item.status==0" @click="confirm(recordList.id,index)">确认报名</span>
                    <span v-else class="active" @click="confirm(recordList.id,index)">再次确认</span>
                </div>
            </div>
        </div>
        <div class="pagestyle">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import instance from '@/services/instance'

export default {
    name: "record",
    data() {
        return {
            activityId:"",
            pageSize: 10,
            currentPage: 1,
            total: 0,
            infoList:[],
            recordList:[],
            showNum:"",
        }
    },
    created() {
        this.activityId = this.$route.params.id;
        this.getInfo();
        this.getRecord();
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getRecord();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getRecord();
        },
        getInfo(){
            instance
                .post('/officialWebsiteMgr/resource/activity/detail',{activityId: this.activityId})
                .then((res) => {
                    if (res.code == 0) {
                        this.infoList=res.data;
                    }
                })
        },
        getRecord(){
            instance
                .post('/officialWebsiteMgr/resource/activity/joinList',{activityId: this.activityId,startPage:this.currentPage})
                .then((res) => {
                    if (res.code == 0) {
                        this.recordList=res.data.list;
                        this.total = res.data.totalCount;
                        this.showNum=res.data.showNum;
                    }
                })
        },
        confirm(id,index){//确认报名
            let msg=this.recordList[index].activityMessage;
            instance
                .post('/officialWebsiteMgr/resource/activity/confirmJoin',{activityId: this.activityId,id:id,activityMessage:msg})
                .then((res) => {
                    if(res.code == 0) {
                        this.recordList[index].status=1;
                        this.$message({
                            message: '确认成功！',
                            type: 'success'
                        });
                    }else{
                        this.$message.error("失败！请联系管理员！");
                    }
                })
        }
    }
}
</script>

<style scoped>
.recordWrap{
    background: #fff;
    margin-top: 10px;
    padding: 20px;
    box-shadow: 0 0 2px rgba(0,0,0,.2);
    border-radius: 3px;
}
.recordTop{
    font-size: 14px;
    color: #bbb;
}
.record{
    border-top: 1px solid #bbb;
}
.record p{
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    color: #666;
}
    .recordMin{
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #409EFF;
        margin-top: 10px;
    }
.minLeft{

}
.minRight{

}
.minRight span{
    display: block;
    background: #409EFF;
    color: #fff;
    height: 30px;
    width:100px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    margin-top: 5px;
    border-radius: 5px;
}
.minRight .active{
    background: #E6A23C;
}
</style>